var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"34E2av8KTUAuoNaJyx13Q"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
import { env } from "next-runtime-env";

Sentry.init({
  dsn: env("NEXT_PUBLIC_SENTRY_DSN"),
  environment: env("NEXT_PUBLIC_ENV"),

  // Replay may only be enabled for the client-side
  integrations: [Sentry.replayIntegration(), Sentry.browserTracingIntegration()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  // Depth at which we capture data from the redux store
  // I set this at just the right depth to capture the relevant user information, see
  // sentryReduxEnhancer in cloud/clients/customer_portal/src/state/store.ts
  normalizeDepth: 5,

  // Number of actions traced before sending the error
  // Kept this at 5 to keep the request payload less than 1 mb
  maxBreadcrumbs: 5,

  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  // release: process.env.NEXT_PUBLIC_RELEASE,
});
